var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-color-list",class:{
        'c-color-list--row': _vm.typeDirection === 'row',
        'c-color-list--column': _vm.typeDirection === 'column',
        'c-color-list--lg': _vm.sizeType === 'lg',

        'c-color-list--decorative': _vm.isDecorative,
    }},[_vm._l((_vm.listColors),function(color){return _c('button',{key:color.id,staticClass:"c-color-list__item outline",class:{
            'c-color-list__item--active': _vm.currentColor === color.id && !_vm.isDecorative,
        },attrs:{"disabled":_vm.isDecorative,"tabindex":_vm.currentColor === color.id && !_vm.isDecorative ? -1 : 0},on:{"click":function($event){return _vm.selectColor(color)}}},[_c('span',{staticClass:"c-color-list__item-circle",style:(_vm.createBgStyle(color.code)),attrs:{"role":"img","alt":color.name + ' цвет'}})])}),(_vm.listColors.length === 0)?_c('button',{staticClass:"c-color-list__item c-color-list__item--clone outline"},[_c('span',{staticClass:"c-color-list__item-circle"})]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }