<script>
    import { apiUrl } from "@/utils";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import ProductCardButtons from "@chipolo/components/ProductCardButtons";
    import ProductPrice from "@chipolo/components/ProductPrice";
    import ProductCardColorList from "@chipolo/components/ProductCardColorList";
    import TooltipBox from "@chipolo/components/TooltipBox";

    export default {
        name: "ProductCard",
        components: {
            TooltipBox,
            ProductCardColorList,
            ProductPrice,
            ProductCardButtons,
        },
        props: {
            product: Object,
            position: Number,
            isSearch: Boolean,
            isAvalible: Boolean,
            isSmall: Boolean,
        },
        data() {
            let isPreOrder = false;
            let currentOffers = this.product.offers.filter((offer) => offer.available);

            if (currentOffers.length === 0) {
                // проверка на предзаказ
                currentOffers = this.product.offers.filter((offer) => !offer.available);
                isPreOrder = true;
            }

            return {
                currentOffers,
                offer: currentOffers[0],
                offerId: currentOffers[0].id,
                previewImage: currentOffers[0].previewImage?.medium,
                price: currentOffers[0].price,
                discountPrice: currentOffers[0].discountPrice,
                discountPercent: currentOffers[0].discountPercent,
                inCart: this.product.inCart || false,
                loading: false,
                isPreOrder,
                previewImageFromOffer: "",
                offerForParams: !this.product.isClone ? undefined : currentOffers[0].id,
                appList: [
                    {
                        ico: "app-chipolo.svg",
                        name: "Chipolo",
                    },
                    {
                        ico: "app-locator.png",
                        name: "«Локатор»",
                    },
                ],
                isHoverBtn: false,
            };
        },
        computed: {
            nameProduct() {
                return this.product.shortName ? this.product.shortName : this.product.name;
            },
            appType() {
                // 238073 - spot
                // 238073 - spot 4
                let filtered = {
                    238073: 1,
                    238084: 1,
                };
                return filtered[this.product.id] || 0;
            },
            scrImage() {
                return this.previewImageFromOffer ? apiUrl(this.previewImageFromOffer) : "";
            },
            tooltipForApple() {
                const text = {
                    "chipolo-one": "iOS 12 и новее",
                    "chipolo-one-4-pack": "iOS 12 и новее",
                    "chipolo-one-spot": "iOS 14.5, iPadOS 14.5 и новее",
                    "chipolo-one-spot-4-pack": "iOS 14.5, iPadOS 14.5 и новее",
                    "chipolo-card": "iOS 12 и новее",
                    "chipolo-plus": "iOS 12 и новее",
                };

                return text[this.product.slug]
                    ? text[this.product.slug]
                    : "iOS 14.5, iPadOS 14.5 и новее";
            },
        },
        methods: {
            apiUrl,
            selectColor(color) {
                let { id, previewImage, price, discountPrice, available } =
                    this.currentOffers.filter((offer) => offer.color.id === color.id)[0];
                this.offerId = id;
                this.previewImageFromOffer = previewImage.medium;
                this.price = price;
                this.discountPrice = discountPrice;
                this.isPreOrder = !available;
                this.inCart = false;
                this.offerForParams = id;
            },
            sendDataLayerEvent() {
                sendAnalytics.send(sendAnalytics.events.productClick, {
                    name: this.product.name,
                    offerId: this.offerId,
                    price: this.discountPrice,
                    position: this.position,
                });
            },
        },
    };
</script>

<template>
    <div
        class="product-card"
        :class="{
            'product-card--loading': loading,
            'product-card--sm': isSmall,
            'product-card--hover': isHoverBtn,
            'product-card--in-cart': inCart,
        }"
    >
        <div class="product-card__header">
            <div class="product-card__img">
                <router-link
                    @click.native.prevent="sendDataLayerEvent"
                    :to="{
                        name: 'Product',
                        params: { slug: product.slug, offer: offerForParams },
                    }"
                    class="product-card__img-link js-lazy-load"
                    tabindex="-1"
                >
                    <img
                        class="product-card__img-product"
                        :data-src="apiUrl(previewImage)"
                        :src="scrImage"
                    />
                    <div class="product-card__preloader">
                        <preloader :show="true" lg-size></preloader>
                    </div>
                    <div v-if="discountPercent" class="product-card__label">
                        -{{ discountPercent }}%
                    </div>
                    <div class="product-card__app-list product-card__app-list--mobile">
                        <img
                            v-show="appList[appType].name === 'Chipolo'"
                            :data-src="`/static/chipolo/product/android.svg`"
                            alt=""
                        />
                        <img :data-src="`/static/chipolo/product/ios.svg`" alt="" />
                    </div>
                </router-link>
            </div>
        </div>

        <div class="product-card__body product-card__body--up">
            <div class="product-card__colors">
                <svg
                    v-if="offerId === 202900"
                    width="20"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M19.5 10a9.5 9.5 0 0 1-9.5 9.5h-.5v-10h10v.5Z"
                        fill="#fff"
                        stroke="#B2B2C5"
                    />
                    <path d="M10 20C4.477 20 0 15.523 0 10h10v10Z" fill="#000" />
                    <path d="M10 0c5.523 0 10 4.477 10 10H10V0Z" fill="#3689D5" />
                    <path d="M10 0C4.477 0 0 4.477 0 10h10V0Z" fill="#FE311F" />
                </svg>
                <product-card-color-list
                    v-else
                    :offers="currentOffers"
                    :current-offer="offer"
                    @select-color="selectColor"
                ></product-card-color-list>
            </div>

            <router-link
                @click.native.prevent="sendDataLayerEvent"
                :to="{
                    name: 'Product',
                    params: { slug: product.slug, offer: offerForParams },
                }"
                class="product-card__wrap"
            >
                <div class="product-card__name">
                    {{ nameProduct }}
                </div>

                <div class="product-card__price">
                    <product-price
                        :price="discountPrice"
                        :old-price="price"
                        :discount-count="discountPercent"
                    ></product-price>
                </div>
            </router-link>
        </div>

        <div class="product-card__body product-card__body--down">
            <div class="product-card__app">
                <img
                    class="product-card__app-ico"
                    :data-src="`/static/chipolo/product/${appList[appType].ico}`"
                    alt=""
                />
                Приложение {{ appList[appType].name }}
                <div class="product-card__app-list product-card__app-list--desktop">
                    <div class="product-card__app-item">
                        <img
                            v-show="appList[appType].name === 'Chipolo'"
                            src="/static/chipolo/product/android.svg"
                            alt=""
                        />
                        <tooltip-box class="product-card__app-tooltip">
                            Android 7 и новее
                        </tooltip-box>
                    </div>
                    <div class="product-card__app-item">
                        <img src="/static/chipolo/product/ios.svg" alt="" />
                        <tooltip-box class="product-card__app-tooltip">
                            {{ tooltipForApple }}
                        </tooltip-box>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="product-card__footer"
            @mouseover="isHoverBtn = true"
            @mouseleave="isHoverBtn = false"
        >
            <product-card-buttons
                :offer-id="offerId"
                :product="product"
                :is-pre-order="isPreOrder"
                :in-cart="inCart"
                :discount-price="discountPrice"
                @on-added-to-cart="inCart = true"
            ></product-card-buttons>
        </div>
    </div>
</template>

<style lang="scss">
    .product-card {
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto;
        border-radius: 21px;
        transition: 0.2s ease-in box-shadow;
        background: linear-gradient(
            180deg,
            rgba(246, 250, 251, 0) 58.3%,
            rgba(246, 250, 251, 0.8) 86.31%
        );

        @include bp($bp-desktop-sm) {
            border-radius: 26px;
            &:hover {
                box-shadow: 0 0 0 3px var(--primary);

                .product-card__img-link {
                    img {
                        transform: scale(1);
                    }
                }
            }
        }
    }

    .product-card__header {
        position: relative;
        background-color: #f6fafb;
        border-radius: 20px 20px 0 0;
        transition: 0.2s ease-in border-radius;

        @include bp($bp-desktop-sm) {
            border-radius: 25px 25px 0 0;
        }
    }

    .product-card__img {
        position: relative;
    }

    .product-card__img-link {
        position: relative;
        display: block;
        padding-bottom: 100%;

        & > img {
            @include center-absolute(0);
            margin: auto;
            max-height: 100%;
            transform: scale(0.97);
            transition: 0.2s ease-in-out transform;
        }
    }

    .product-card__preloader {
        opacity: 1;
    }

    .product-card__label {
        position: absolute;
        left: 8px;
        top: -4px;
        width: 36px;
        height: 44px;
        font-weight: bold;
        font-size: 12px;
        line-height: 36px;
        color: #fff;
        text-align: center;
        background-size: contain;
        background-image: url("~@chipolo/assets/icons/sale.svg");

        @include bp($bp-desktop-sm) {
            width: 44px;
            height: 54px;
            font-size: 14px;
            line-height: 44px;
            left: 12px;
            top: -12px;
        }
    }

    .product-card__img-product {
        z-index: 1;
        display: none;
        animation: fadeIn 0.3s ease-in-out;

        @keyframes fadeIn {
            from {
                opacity: 0;
                transform: scale(0.9);
            }
            to {
                opacity: 1;
                transform: scale(0.97);
            }
        }
        &[lazy="loaded"] {
            display: block;
            & + .product-card__preloader {
                opacity: 0;
            }
        }
    }

    .product-card__body {
        padding-right: 5px;
        padding-left: 5px;

        @include bp($bp-desktop-sm) {
            padding-right: 12px;
            padding-left: 12px;
        }
    }

    .product-card__colors {
        margin-top: -12px;
        margin-bottom: 10px;
        min-height: 20px;

        .c-color-list {
            flex-wrap: wrap;
        }

        .c-color-list__item {
            margin-top: 12px;
        }

        & > svg {
            margin-top: 12px;
        }
    }

    .product-card__wrap {
        display: block;
    }

    .product-card__name {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 4px;
        color: var(--primary-text);

        @include bp($bp-desktop-sm) {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 6px;
        }
    }

    .product-card__price {
        display: block;
        margin-bottom: 8px;
    }

    .product-card__app {
        display: flex;
        align-items: center;
        font-size: 10px;
        color: var(--secondary-text);

        @include bp($bp-desktop-sm) {
            font-size: 12px;
        }
    }

    .product-card__app-ico {
        width: 16px;
        margin-right: 4px;

        @include bp($bp-desktop-sm) {
            width: 28px;
        }
    }

    .product-card__app-list {
        margin-left: auto;

        @include bp($bp-desktop-sm) {
            display: flex;
        }

        img {
            @include box(16px);

            @include bp($bp-desktop-sm) {
                @include box(28px);
            }
            &:nth-child(2) {
                margin-left: 3px;
            }
        }
    }

    .product-card__app-item {
        position: relative;
        &:hover {
            .product-card__app-tooltip {
                display: block;
            }
        }
    }

    //
    // --mod
    //

    .product-card--loading {
        .product-card__btn {
            opacity: 1;
        }
    }

    .product-card--sm {
        .product-card__name {
            font-size: 14px;
        }

        .product-card__app {
            font-size: 10px;

            @include bp($bp-desktop-sm) {
                font-size: 12px;
            }
        }

        .product-card__app-list {
            img {
                @include box(16px);
            }
        }

        .product-card__body {
            padding-left: 6px;
            padding-right: 6px;
        }

        .product-card__img-link > img {
            max-height: 90%;
        }
    }

    .product-card--hover {
        &:hover {
            box-shadow: 0 0 0 3px var(--secondary);
        }
    }

    .product-card__body--up {
        position: relative;
        padding-top: 8px;

        @include bp($bp-desktop-sm) {
            padding-top: 16px;
        }
    }

    .product-card__body--down {
        position: relative;
        margin-top: auto;
        padding-bottom: 8px;

        @include bp($bp-desktop-sm) {
            padding-bottom: 10px;
        }
    }

    .product-card__app-list--desktop {
        display: none;

        @include bp($bp-desktop-sm) {
            display: flex;
        }
    }

    .product-card__app-list--mobile {
        display: flex;
        position: absolute;
        top: 6px;
        right: 6px;

        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .product-card--in-cart {
        &:hover {
            box-shadow: 0 0 0 3px var(--primary--darker);
        }
    }
</style>
