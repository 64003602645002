var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-card",class:{
        'product-card--loading': _vm.loading,
        'product-card--sm': _vm.isSmall,
        'product-card--hover': _vm.isHoverBtn,
        'product-card--in-cart': _vm.inCart,
    }},[_c('div',{staticClass:"product-card__header"},[_c('div',{staticClass:"product-card__img"},[_c('router-link',{staticClass:"product-card__img-link js-lazy-load",attrs:{"to":{
                    name: 'Product',
                    params: { slug: _vm.product.slug, offer: _vm.offerForParams },
                },"tabindex":"-1"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent.apply(null, arguments)}}},[_c('img',{staticClass:"product-card__img-product",attrs:{"data-src":_vm.apiUrl(_vm.previewImage),"src":_vm.scrImage}}),_c('div',{staticClass:"product-card__preloader"},[_c('preloader',{attrs:{"show":true,"lg-size":""}})],1),(_vm.discountPercent)?_c('div',{staticClass:"product-card__label"},[_vm._v(" -"+_vm._s(_vm.discountPercent)+"% ")]):_vm._e(),_c('div',{staticClass:"product-card__app-list product-card__app-list--mobile"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.appList[_vm.appType].name === 'Chipolo'),expression:"appList[appType].name === 'Chipolo'"}],attrs:{"data-src":"/static/chipolo/product/android.svg","alt":""}}),_c('img',{attrs:{"data-src":"/static/chipolo/product/ios.svg","alt":""}})])])],1)]),_c('div',{staticClass:"product-card__body product-card__body--up"},[_c('div',{staticClass:"product-card__colors"},[(_vm.offerId === 202900)?_c('svg',{attrs:{"width":"20","height":"20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M19.5 10a9.5 9.5 0 0 1-9.5 9.5h-.5v-10h10v.5Z","fill":"#fff","stroke":"#B2B2C5"}}),_c('path',{attrs:{"d":"M10 20C4.477 20 0 15.523 0 10h10v10Z","fill":"#000"}}),_c('path',{attrs:{"d":"M10 0c5.523 0 10 4.477 10 10H10V0Z","fill":"#3689D5"}}),_c('path',{attrs:{"d":"M10 0C4.477 0 0 4.477 0 10h10V0Z","fill":"#FE311F"}})]):_c('product-card-color-list',{attrs:{"offers":_vm.currentOffers,"current-offer":_vm.offer},on:{"select-color":_vm.selectColor}})],1),_c('router-link',{staticClass:"product-card__wrap",attrs:{"to":{
                name: 'Product',
                params: { slug: _vm.product.slug, offer: _vm.offerForParams },
            }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent.apply(null, arguments)}}},[_c('div',{staticClass:"product-card__name"},[_vm._v(" "+_vm._s(_vm.nameProduct)+" ")]),_c('div',{staticClass:"product-card__price"},[_c('product-price',{attrs:{"price":_vm.discountPrice,"old-price":_vm.price,"discount-count":_vm.discountPercent}})],1)])],1),_c('div',{staticClass:"product-card__body product-card__body--down"},[_c('div',{staticClass:"product-card__app"},[_c('img',{staticClass:"product-card__app-ico",attrs:{"data-src":("/static/chipolo/product/" + (_vm.appList[_vm.appType].ico)),"alt":""}}),_vm._v(" Приложение "+_vm._s(_vm.appList[_vm.appType].name)+" "),_c('div',{staticClass:"product-card__app-list product-card__app-list--desktop"},[_c('div',{staticClass:"product-card__app-item"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.appList[_vm.appType].name === 'Chipolo'),expression:"appList[appType].name === 'Chipolo'"}],attrs:{"src":"/static/chipolo/product/android.svg","alt":""}}),_c('tooltip-box',{staticClass:"product-card__app-tooltip"},[_vm._v(" Android 7 и новее ")])],1),_c('div',{staticClass:"product-card__app-item"},[_c('img',{attrs:{"src":"/static/chipolo/product/ios.svg","alt":""}}),_c('tooltip-box',{staticClass:"product-card__app-tooltip"},[_vm._v(" "+_vm._s(_vm.tooltipForApple)+" ")])],1)])])]),_c('div',{staticClass:"product-card__footer",on:{"mouseover":function($event){_vm.isHoverBtn = true},"mouseleave":function($event){_vm.isHoverBtn = false}}},[_c('product-card-buttons',{attrs:{"offer-id":_vm.offerId,"product":_vm.product,"is-pre-order":_vm.isPreOrder,"in-cart":_vm.inCart,"discount-price":_vm.discountPrice},on:{"on-added-to-cart":function($event){_vm.inCart = true}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }