<script>
    import { removeDuplicates } from "@/utils";

    export default {
        name: "ProductCardColorList",
        props: {
            typeDirection: {
                default: "row",
                type: String,
            },
            currentOffer: Object,
            offers: Array,
            sizeType: {
                default: "sm",
                type: String,
            },
            isDecorative: Boolean,
        },
        data() {
            return {
                currentColor: this.currentOffer.color?.id,
            };
        },
        computed: {
            listColors() {
                return removeDuplicates(
                    this.offers
                        .filter((offer) => offer.color && offer.available)
                        .map((offer) => offer.color)
                );
            },
        },
        methods: {
            selectColor(color) {
                this.currentColor = color.id;
                this.$emit("select-color", color);
            },
            createBgStyle(codeColor) {
                return codeColor === "ffffff"
                    ? `background-color: #${codeColor}; box-shadow: 0 0 0 1px #bdbdbd;`
                    : `background-color: #${codeColor};`;
            },
        },
    };
</script>

<template>
    <div
        class="c-color-list"
        :class="{
            'c-color-list--row': typeDirection === 'row',
            'c-color-list--column': typeDirection === 'column',
            'c-color-list--lg': sizeType === 'lg',

            'c-color-list--decorative': isDecorative,
        }"
    >
        <button
            class="c-color-list__item outline"
            :class="{
                'c-color-list__item--active': currentColor === color.id && !isDecorative,
            }"
            v-for="color in listColors"
            :key="color.id"
            @click="selectColor(color)"
            :disabled="isDecorative"
            :tabindex="currentColor === color.id && !isDecorative ? -1 : 0"
        >
            <span
                class="c-color-list__item-circle"
                :style="createBgStyle(color.code)"
                role="img"
                :alt="color.name + ' цвет'"
            ></span>
        </button>
        <button
            v-if="listColors.length === 0"
            class="c-color-list__item c-color-list__item--clone outline"
        >
            <span class="c-color-list__item-circle"></span>
        </button>
    </div>
</template>

<style lang="scss">
    .c-color-list {
        display: flex;
    }

    .c-color-list__item {
        cursor: pointer;
        margin-right: 8px;

        &:hover {
            //.c-color-list__item-circle {
            //    transform: scale(1.05);
            //}
        }
    }

    .c-color-list__item-circle {
        display: block;
        position: relative;
        @include box(18px);
        border-radius: 50%;
        transition: 0.2s ease all;

        &:after {
            content: "";
            position: absolute;
            top: -3px;
            left: -3px;
            @include box(24px);
            border: 2px solid transparent;
            border-radius: 50%;
            transition: box-shadow 0.2s ease-in;
            transform: rotate(90deg);
        }
    }

    //
    // --mod
    //

    .c-color-list--decorative {
        pointer-events: none;
    }

    .c-color-list__item--active {
        pointer-events: none;

        &:hover {
            .c-color-list__item-circle {
                transform: scale(1);
            }
        }

        .c-color-list__item-circle {
            box-shadow: none !important;
            &:after {
                border-color: var(--secondary);
            }
        }
    }

    .c-color-list--lg {
        min-height: 24px;

        .c-color-list__item-circle {
            @include box(24px);

            &:after {
                top: -3px;
                left: -3px;
                @include box(30px);
            }
        }
    }

    .c-color-list__item--clone {
        .c-color-list__item-circle {
            &:after {
                border-color: #ddd;
            }
        }
    }
</style>
