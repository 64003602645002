<script>
    export default {
        name: "ProductPrice",
        props: {
            oldPrice: Number,
            price: Number,
            discountCount: Number,
            isSmallSize: Boolean,
            isMediumSize: Boolean,
            isCardSize: Boolean,
        },
        computed: {
            isDiscount() {
                return this.discountCount;
            },
        },
    };
</script>

<template>
    <div
        class="product-price"
        :class="{
            'product-price--sm': isSmallSize,
            'product-price--md': isMediumSize,
            'product-price--card': isCardSize,
        }"
    >
        <div class="product-price__wrap">
            <div
                class="product-price__number product-price__number--current"
                :class="{
                    'product-price__number--discount': isDiscount,
                }"
            >
                {{ price | formatPrice }}₽
            </div>
            <div v-if="isDiscount" class="product-price__number product-price__number--old">
                {{ oldPrice | formatPrice }}₽
            </div>
        </div>
        <div v-if="isDiscount && isCardSize" class="product-price__label">
            {{ discountCount }}%
        </div>
    </div>
</template>

<style lang="scss">
    .product-price {
        position: relative;
        display: flex;
        align-items: center;
    }

    .product-price__wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }

    .product-price__number {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: var(--primary-text);

        @include bp($bp-desktop-sm) {
            font-size: 20px;
        }
    }

    .product-price__label {
        width: 56px;
        height: 28px;
        background: var(--danger-text);
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
        border-radius: 30px;
        text-align: center;
        color: #fff;
        margin-left: 12px;
    }

    //
    // --mod
    //

    .product-price__number--current {
        margin-right: 8px;
    }

    .product-price__number--discount {
        color: var(--danger-text);
    }

    .product-price__number--old {
        font-weight: 500;
        font-size: 10px;
        color: var(--secondary-text);
        text-decoration: line-through;

        @include bp($bp-desktop-sm) {
            font-size: 15px;
        }
    }

    .product-price--card {
        .product-price__number {
            font-size: 18px;
            line-height: 26px;

            @include bp($bp-desktop-sm) {
                font-size: 32px;
                line-height: 32px;
            }
        }

        .product-price__number--old {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 2px;

            @include bp($bp-desktop-sm) {
                font-size: 16px;
                line-height: 20px;
            }
        }

        //.product-price__label {
        //    width: 36px;
        //    font-size: 12px;
        //    line-height: 18px;
        //    height: 18px;
        //
        //    @include bp($bp-desktop-sm) {
        //        width: 52px;
        //        font-size: 16px;
        //        line-height: 24px;
        //        height: 24px;
        //    }
        //}
    }
</style>
